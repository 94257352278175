<template>
    <div style="height:100%">
        <div class="page-header">客户标签</div>
        <div class="block tab flex flex-align-center">
            <div class="tab_menu flex-1">
                <div v-for="(item,index) in tabList" :key="index" @click="tab(index)" class="flex menu_item" :class="{'cur':tabIndex == index}">
                    <span>{{item.name}}</span>
                </div>
            </div>
            <div class="">
                <el-button @click="add()" size="small" type="primary">添加标签</el-button>
            </div>
        </div>
        <div class="block" style="height: calc(100% - 180px)">
            <div class="list" style="height: calc(100% - 60px);overflow-y: auto">
                <el-table :data="list" style="width: 100%">
                    <el-table-column prop="id" label="ID" width="120">
                    </el-table-column>
                    <el-table-column prop="name" label="标签名称">
                    </el-table-column>
                    <el-table-column prop="name" label="状态">
                        <template slot-scope="scope">
                            <div v-if="scope.row.stat==1">启用</div>
                            <div v-if="scope.row.stat==2">禁用</div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="address" label="操作" width="120">
                        <template slot-scope="scope">
                            <el-button type="text" size="small" @click="edit(scope.row,scope.$index)">
                                <span class="icon iconfont icon-bianji1"></span>&nbsp;编辑
                            </el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <!-- <div style="padding:12px">
                <el-pagination background layout="prev, pager, next" :current-page.sync="queryOpt.pageNumber" :page-size="queryOpt.pageCount" :total="total"></el-pagination>
            </div> -->
        </div>

        <el-dialog :title="popTitle" :visible.sync="popVisible" :destroy-on-close="true">
            <EditTags :form="ruleForm" :popTitle="popTitle" @hide="hide"></EditTags>
        </el-dialog>
    </div>
</template>
<script>
    import { listTag } from '@/service/crm';
    import EditTags from './EditTags.vue';
    export default {
        components: {
            EditTags,
        },
        data() {
            return {
                popVisible: false,
                popTitle: '',
                // 1普通标签，2状态标签，3产品问题标签，4营销目的标签
                tabList:[
                    {name:"行为标签"},
                    {name:"联系标签"},
                    {name:"产品问题标签"},
                    {name:"营销目的标签"},
                ],
                tabIndex:0,
                list:[],
                ruleForm:"",
            }
        },
        created() {
            this.refreshItems();
        },
        mounted() {},
        methods: {
            // 选项卡
            tab(index) {
                this.tabIndex = index;
                this.refreshItems();
            },
            refreshItems() {
                console.log('refreshItems');
                listTag({tag_type:this.tabIndex+1,}).then(rst => {
                    this.list = rst;
                    console.log(this.list);
                }).catch(err => {
                    console.log(err);
                    this.$message.error(err.message);
                });
            },
            add(item,index) {
                if (this.tabIndex==0) {
                    this.popTitle = "新增普通标签";
                } else if (this.tabIndex==1) {
                    this.popTitle = "新增状态标签";
                } else if (this.tabIndex==2) {
                    this.popTitle = "新增产品问题标";
                } else if (this.tabIndex==3) {
                    this.popTitle = "新增营销目的标";
                }
                this.popVisible = true;
                this.ruleForm = {
                    name:null,
                    tag_type:this.tabIndex+1,
                }
            },
            edit(item,index) {
                if (this.tabIndex==0) {
                    this.popTitle = "修改普通标签";
                } else if (this.tabIndex==1) {
                    this.popTitle = "修改状态标签";
                } else if (this.tabIndex==2) {
                    this.popTitle = "修改产品问题标";
                } else if (this.tabIndex==3) {
                    this.popTitle = "修改营销目的标";
                }
                this.popVisible = true;
                this.ruleForm = {
                    id:item.id,
                    name:item.name,
                    stat:item.stat,
                }
            },
            hide() {
                this.popVisible=false;
                this.refreshItems();
            }
        }
    }
</script>
<style scoped src="../style.css"></style>
<style scoped>
    .icon {
        font-size: 13px;
    }
    /*----选项卡----*/
    .tab {
        padding:0 20px;
    }
    .tab_menu {
        /* min-width: 460px; */
        /* background: #fff;
        padding: 0 20px;
        margin: 20px; */
    }
    .tab_menu .menu_item{
        display: inline-block;
        vertical-align: top;
        width: 85px;
        height: 55px;
        text-align: center;
        line-height: 55px;
        margin-right:30px;
        cursor: pointer;
        font-size: 14px;
        color: #808080;
    }
    .tab_menu .menu_item.cur{
        color: #4D4D4D;
        border-bottom: 2px solid #4D4D4D;
    }
</style>